@import 'mixins';

.popup {
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 0; top: 0; right: auto; bottom: auto;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 5rem 0;
  transition: opacity 0.3s ease;
  background-color: rgba(0,0,0,.65);
  color: black;
  overflow-x: scroll;
   display: flex;
  animation-name: imgTree1Anim;
  animation-play-state: running;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &--hide {
    animation-name: closePopupAnim;
    animation-play-state: running;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@keyframes imgTree1Anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes closePopupAnim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.popup__wrapper {
  position: relative;
  width: 46.375rem;
  padding: 3.125rem 4rem 2.25rem;
  background-color: white;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3;
}

.popup__title {
  display: block;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
}

.popup__answer {
  margin-bottom: 2rem;

  & a {
    color: #2D63B9;
  }
}

.popup__close, .popup__close-btn {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}
.popup__close {
  margin-top: auto;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  color: #2D63B9;
}

.popup__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.125rem;
  height: 3.125rem;
  background-color: #EF8A52;

  &::after, &::before {
    @include pseudo;

    width: 0.125rem;
    height: 1.875rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@media (max-width: 991px) {
  .popup {
    align-items: flex-start;
    padding: 2rem 1.25rem;
  }
}