@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.formats {
  margin-bottom: 4.6875rem;
}

.title {
  @include mainTitle;

  margin-bottom: 2.8rem;
  //text-shadow: 0px 0px 3.75rem rgba(0, 0, 0, 0.65);
}

.format__titleWrap {
  margin-bottom: 13.3125rem;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.item {
  transition: outline-color 0.3s;
  outline: 3px solid #002e5c;
}

.itemFocused {
  outline: 3px solid $color-brand;
}

.itemBody {
  padding: 2rem 1.5rem;
  padding-bottom: 0;

  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
}

.itemTitle {
  margin-bottom: 1rem;

  font-size: 1.75rem;
  font-weight: 900;
  line-height: 1.46;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #002e5c;
}

.format__sub {
  display: block;
  margin-bottom: 1.5rem;
}

.text {
  margin-bottom: 2rem;
}

.costWrap {
  margin-bottom: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.costWrapNext {
  margin-bottom: 2rem;

  font-size: 0.875rem;

  opacity: 0.5;
}

.costText {
  display: block;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #002e5c;
}

.cost {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1;
  color: #002e5c;

  transition: color 0.3s;
}

.costFocused {
  color: $color-brand;
}

.costCurrent {
  margin-top: auto;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  font-size: 0.8125rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);

  border-bottom: 2px solid #002e5c;
}

.costNext {
  font-size: 1.5rem;
  font-weight: 700;
}

.costNextValue {
  font-size: 0.875rem;
  font-weight: 900;
}

.btn {
  @include btnPrimary;

  text-align: left;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;

  background-color: #002e5c;
  background-image: url("../../assets/images/svg/icon-arrow-white.svg");
  background-repeat: no-repeat;
  background-position: center right 1.25rem;

  &:hover,
  &:active {
    background-color: $color-brand;
  }

  &:disabled {
    cursor: default;
  }

  &:disabled:hover,
  &:disabled:active {
    background-color: gray;
  }
}

@media (max-width: 1440px) and (min-width: 992px) {
  .title{
    font-size: 5.5rem;
  }

  .format__titleWrap {
    margin-bottom: 10rem;
  }

  .text {
    margin-bottom: 2rem;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .title{
    font-size: 4rem;
  }

  .format__titleWrap {
    margin-bottom: 7rem;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .formats {
    margin-bottom: 3rem;
  }

  .title {
    font-size: 3rem;
  }

  .format__titleWrap {
    margin-bottom: 5rem;
  }

  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .item {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .itemFocused {
    outline: 3px solid #283954;
  }

  .costFocused {
    color: #202651;
  }

  .text {
    margin-bottom: 2.25rem;
  }

  .btn {
    &:hover,
    &:active {
      background-color: #283954;
    }
  }
}

@media (max-width: 767px) {
  .formats {
    margin-bottom: 2.5rem;
  }

  .title {
    font-size: 2.375rem;
    max-width: 21rem;
  }

  .format__titleWrap {
    padding-top: 10rem;
    padding-bottom: 3.125rem;
    margin-bottom: 0;
    background-image: url('../../assets/images/mob/format-back-mob-min.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right 0;
  }

  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .item {
    margin-bottom: 1.875rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .itemFocused {
    outline: 3px solid #283954;
  }

  .costFocused {
    color: #202651;
  }

  .format__sub {
    margin-bottom: 1rem;
  }

  .text {
    margin-bottom: 2.25rem;
  }

  .btn {
    &:hover,
    &:active {
      background-color: #283954;
    }
  }
}

@media (max-width: $mobile-width) {
  .title {
    margin-bottom: 1rem;

    font-size: 1.5rem;
  }

  .list {
    display: block;
  }

  .itemBody {
    padding: 1.5rem 1rem;
    padding-bottom: 0;
  }

  .itemTitle {
    font-size: 1.125rem;
  }

  .text {
    font-size: 0.875rem;
  }
}
