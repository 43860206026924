@import 'mixins';

.nav {
  margin-bottom: 1rem;
}

//.nav__title {
//  @include mainTitle;
//
//  display: inline-block;
//  padding-bottom: 0.5rem;
//  border-bottom: 0.625rem solid #2D63B9;
//  margin-bottom: 3.5625rem;
//}

.nav__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.875rem;
  column-gap: 2.5rem;
  margin-bottom: 4.375rem;
}

.nav__item {
  padding: 1.5rem 3.125rem 2.25rem;
  background-color: white;
  min-height: 9.5rem;
  display: flex;
  align-items: center;
  border: 2px solid #283954;
}

.nav__image {
  margin-right: 1.875rem;
}

.nav__subtitle {
  display: block;
  max-width: 28rem;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.27;
  color: #202651;
  text-transform: uppercase;

  &--small {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.27;
    text-transform: none;
  }
}

.nav__textWrap {
  & b + p {
    margin-top: 0.3rem;
  }
}

.nav__logo {
  &--yandex {
    margin-right: 1.125rem;
  }
}

.nav__btn {
  width: 100%;
  max-width: 21.6rem;
  min-height: 2.5rem;
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1.25;
  margin-top: 1.25rem;
}

.nav__map {
  width: 100%;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .nav {
    padding-bottom: 5rem;
  }

  .nav__btn {
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  .nav {
    padding-bottom: 4rem;
  }

  .nav__title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .nav__list {
    display: block;
  }

  .nav__item {
    padding: 1rem;
    margin-bottom: 1.25rem;

    &:last-of-type {
      margin-bottom: 0rem;
    }

    &--navig {
      margin-bottom: 4.625rem;
    }
  }
}

@media (max-width: 767px) {
  .nav {
    padding-bottom: 2.625rem;
  }

  .nav__title {
    font-size: 1.625rem;
    padding-bottom: 0.875rem;
    border-width: 0.25rem;
    margin-bottom: 1.875rem;
  }

  .nav__list {
    margin-bottom: 2.5rem;
  }

  .nav__item {
    padding: 0.8rem;
  }

  .nav__image {
    margin-right: 1rem;
    width: 1.875rem;
  }

  .nav__subtitle {
    font-size: 1.125rem;
  }

  .nav__btn {
    font-size: 0.75rem;
  }

  .nav__map {
    display: none;
  }

  .nav__picture {
    background-image: url('../images/nav/map1x-min.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    min-height: 400px;
  }
}