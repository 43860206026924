@import "variables";

@mixin pseudo {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

@mixin title42_53 {
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.26;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

@mixin title26_33 {
  font-size: 1.625rem;
  line-height: 1.27;
}

@mixin pseudo {
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

@mixin btnPrimary {
  padding: 1.4rem;
  display: block;
  width: 100%;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  color: $color-main;
  text-align: center;
  text-decoration: none;
  background-color: $color-brand;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover,
  &:active {
    background-color: darken($color-brand, 10%);
  }
}

@mixin btnAnchor {
  @include btnPrimary;

  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

@mixin centerman {
  margin: 0 auto;
  padding: 2.25rem 3.75rem;
  padding-bottom: 0;
  max-width: $desktop-width;
}

@mixin centering {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin mainTitle {
  font-size: 4.5rem;
  font-weight: 900;
  color: #002e5c;
  text-transform: uppercase;
}

@mixin mainTitleMobile {
  font-size: 2.25rem;
}

@mixin fontStyle($font: 1rem, $lh: 1.27, $weight: 400) {
  font-size: $font;
  line-height: $lh;
  font-weight: $weight;
}


@mixin decorLine {
  @include pseudo;

  left: 0;
  bottom: -1rem;
  width: 6rem;
  height: 0.5rem;
  border-radius: 0.2rem;
  background-color: $color-brand;
}

@mixin mainText {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
}

@mixin listCounter {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 3rem;
    counter-increment: myCounter;
    content: "0" counter(myCounter) ".";
  }
}

@mixin sliderBtn {
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: $color-brand;

  &::before {
    @include pseudo;

    top: 31%;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    width: 1.75rem;
    height: 2px;
    background-color: $color-main;
  }

  &::after {
    @include pseudo;

    bottom: 31%;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 1.75rem;
    height: 2px;
    background-color: $color-main;
  }
}

@mixin pseudo-cross-btn($color: #c4c4c4, $w: 1rem, $h: 2px, $r: 0deg) {
  &::after, &::before {
    @include pseudo;
    width: $w;
    height: $h;
    background-color: $color;
    border-radius: 3px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) rotate(0);
  }

  &::after {
    transform: translateX(-50%) rotate($r);
  }

  &::before {
    transform: translateX(-50%) rotate($r - 90deg);
  }
}

