@import '../../assets/styles/mixins';

.title {
  @include mainTitle;

  margin-bottom: 2rem;
}

.desc {
  max-width: 27.8rem;

  font-size: 1.125rem;
}

.galleryRel {
  width: 100% !important;
  overflow-x: hidden;
}

.gallery .galleryContainer {
  padding: 7rem 0 8rem;
  width: 100%;
  height: 100%;
}

.gallery__wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.slide {
  //position: relative;
  max-width: 19.375rem;
  flex-grow: 1;
  will-change: transform, filter;
  transition: transform 0.3s ease, filter 0.3s ease;

  &:hover {
    transform: scale(1.45);
    z-index: 1;
    filter: drop-shadow(0px 0px 1rem rgba(0, 0, 0, 0.65));
  }

  & img {
    width: 100%;
  }
}

.gallery__ban {
  width: 16.25rem;
  padding: 0 2.5rem;
  height: 3.125rem;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  bottom: -1.25rem;
  left: 50%;
  transform: translateX(-50%);

  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.02em;
  color: white;
  text-align: center;
}

.controls {
  display: none;
  z-index: 10;
}

@media (max-width: 1440px) and (min-width: 992px) {
  .gallery {
    overflow-x: hidden;
  }

  .galleryContainer {
    padding: 6rem 0;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .gallery .galleryContainer {
    padding: 4rem 0;
  }
}

@media (max-width: 991px) {
  .title {
    font-size: 3.3rem;
  }

  .gallery  .galleryContainer {
    padding: 1rem 0;
    overflow: hidden;
  }

  .slide {
    flex-shrink: 0;
    &:hover {
      transform: scale(1);
      z-index: auto;
      filter: none;
    }
  }

  .galleryRel {
    padding: 0;
    position: relative;
  }

  .controls {
    width: calc(100% - 3rem);
    display: none; // flex
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.5rem;
    pointer-events: none;
  }

  .btn {
    position: relative;
    padding: 0;
    pointer-events: all;
    background-color: #EF8A52;
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    &::after {
      @include pseudo;

      width: 0.625rem;
      height: 0.625rem;
      border: 2px solid transparent;
      border-left-color: white;
      border-bottom-color: white;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .btnPrev::after {
    left: 0.875rem;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .btnNext::after {
    left: 0.65rem;
    transform: translate(-50%, -50%) rotate(-135deg);
  }
}

@media (max-width: 767px) {
  .gallery {
    margin-bottom: 2rem;
  }

  .title {
    margin-bottom: 1.5rem;

    font-size: 1.5rem;
  }

  .desc {
    margin-bottom: 2.75rem;
  }

  .gallery__titleWrap {
    margin-bottom: 2rem;
  }

  .gallery .galleryContainer {
    padding: 0;
  }

  .slide {
    width: 100%;

    &:hover {
      transform: scale(1);
      filter: none;
      z-index: auto;
    }
  }
}