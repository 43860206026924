// #header
.container {
  width: 100%;
  margin: 0 auto;

  &--fullScreen {
    width: 90rem;
  }

  &--big {
    width: 82.5rem;
  }

  &--small {
    width: 70.5rem;
  }
}

@media (max-width: 1440px) and (min-width: 992px) {
 .container {
    &--big {
      width: 70rem;
    }

    &--small {
      width: 60rem;
    }

    &--fullScreen {
      width: 100%;
    }
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .container {
    &--big {
      width: 60rem;
    }

    &--small {
      width: 50rem;
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .container {
    padding: 0 1.25rem;

    &--big, &--small {
      width: 45rem;
    }

    &--fullScreen {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    margin: 0 auto;

    &--fullScreen, &--big, &--small {
      width: 100%;
      padding: 0 1.25rem;
    }
  }
}