@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';


.title {
  @include mainTitle;

  margin-bottom: 2.5rem;
}

@media (max-width: $tablet-width) {
  .title {
    font-size: 3.3rem;
  }
}

@media (max-width: $mobile-width) {
  .title {
    margin-bottom: 2rem;

    font-size: 1.5rem;
  }
}