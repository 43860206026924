@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.video {
  position: relative;
  margin-bottom: 2.5rem;

  //display: flex;
  width: 100%;
  //width: 40.3125rem;
  //height: 22.375rem;

  box-sizing: content-box;

  & video {
    width: inherit;
    height: inherit;

    object-fit: cover;
    border-radius: 0.3125rem;
  }
}

.videoBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 5.625rem;
  height: 5.625rem;
  padding: 0;
  background-color: white;
  border-radius: 50%;
  border: 0.1875rem solid #202651;
  cursor: pointer;
  outline: none;
  transition: transform 0.25s ease;

  &::after {
    @include pseudo;

    width: 1.1875rem;
    height: 1.75rem;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    background-image: url('../../../assets/images/svg/play.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &:active {
    transform: translate(-50%, -50%) scale(0.95);
  }
}

.songName {
  position: relative;
  padding: 0.5rem;
  padding-left: 1.1rem;

  font-size: 0.875rem;

  &::before {
    @include pseudo;

    left: 0;
    width: 1rem;
    height: 1rem;
    background-image: url("../../../assets/images/svg/icon-music-note.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (max-width: $mobile-width) {
  .videoBtn {
    width: 36px;
    height: 36px;

    border-width: 1px;

    &::after {
      width: 7px;
      height: 11px;
    }
  }
}