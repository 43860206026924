@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 1.5rem;

  @media(max-width: 991px) {
    align-items: flex-start;
  }

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.6);
}

.container {
  position: relative;
  //width: 100%;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.3rem;
  height: 3.3rem;
  background-color: $color-brand;
  border: none;
  border-top-right-radius: 0.51rem;
  cursor: pointer;
  z-index: 10;

  &::after, &::before {
    @include pseudo;

    width: 1.5rem;
    height: 0.125rem;
    background-color: #000000;
    border-radius: 0.125rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover,
  &:active {
    background-color: lighten($color-brand, 10%);
  }
}

@media (max-width: $mobile-width) {
  .overlay {
    padding: 0;
  }
}
