@import 'mixins';

.partners {
  margin-bottom: 7.25rem;
}

.partners__title {
  @include title42_53;

  display: inline-block;
  padding-bottom: 0.875rem;
  border-bottom: 0.625rem solid #2D63B9;
  margin-bottom: 4.1875rem;
}

.partners__rel {
  position: relative;
}

.partners__container {
  position: relative;
  width: 66.5rem;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.partners__controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%; left: 0;
  transform: translateY(-50%);
  position: absolute;
  pointer-events: none;
}

@media (max-width: 1440px) and (min-width: 992px) {
  .partners__container {
    width: 100%;
    padding: 0 1.75rem;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .partners {
    margin-bottom: 5rem;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .partners {
    margin-bottom: 4rem;
  }

  .partners__title {
    font-size: 2rem;
    margin-bottom: 3.25rem;
  }

  .partners__container {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .partners {
    margin-bottom: 6.25rem;
  }

  .partners__title {
    font-size: 1.625rem;
    margin-bottom: 3rem;
    max-width: 10rem;
    border-width: 0.25rem;
  }

  .partners__container {
    width: 100%;
  }
}