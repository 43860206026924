@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.pageTop {
  position: relative;
  padding-top: 10.4375rem;
  padding-bottom: 15rem;

  background-image:
          url("../../assets/images/bg1@1x.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  &::after {
    @include pseudo();

    top: auto;
    bottom: 0;

    width: 100%;
    height: 100px;

    background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.content {
  position: relative;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 63.61%;
  max-width: 635px;

  color: #ffffff;
}

.raceLogo {
  width: 29.37%;
}

.logoWinter {
  min-width: 20rem;
  width: 100%;

  mix-blend-mode: lighten;
}

.timeAddress {
  position: absolute;
  top: 40%;
  left: -10rem;
  transform: translateY(-50%);

  width: 10rem;

  text-align: center;
}

.address {
  left: auto;
  right: -10rem;
}

.desc {
  margin-bottom: 1.75rem;

  max-width: 43.75rem;

  font-size: #{"max(1.125rem, 1.5vw)"};
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.link {
  @include btnPrimary;

  padding: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  max-width: 15.1rem;
}

@media (max-width: 1500px) {
  .pageTop {
    padding-top: 6rem;
  }

  .content {
    max-width: 350px;
  }

  .desc {
    font-size: 1.25rem;
  }
}

@media (max-width: $tablet-width) {
  .pageTop {
    position: static;

    &::after {
      display: none;
    }
  }
}

@media (max-width: $mobile-width) {
  .pageTop {
    padding-top: 6.125rem;
    padding-bottom: 15rem;
  }

  .content {
    width: 100%;
  }

  .timeAddress {
    position: static;
    transform: translate(0, -1rem);

    width: auto;

    font-size: 1.125rem;
    font-weight: 700;
  }

  .desc {
    margin-bottom: 2.25rem;
    padding: 0 2rem;
  }

  .link {
    max-width: 17.5rem;
  }
}
