@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';


.corp {
  position: relative;
  margin-bottom: 1.6rem;
  padding-bottom: 19rem;

  &::after {
    @include pseudo;

    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    height: 13.3125rem;

    background-image: url("../../assets/images/cloud-corp.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.membersImg {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: -1;
}

.title {
  @include mainTitle;

  margin-bottom: 2.8rem;
}

.content {
  margin-bottom: 3rem;

  max-width: 27.8rem;

  font-size: 1.125rem;
}

.text {
  margin-bottom: 1.5rem;
}

.linkMail {
  color: #000000;
  font-weight: 700;

  border-bottom: 1px dotted #000000;

  &:hover {
    color: $color-brand;

    border-color: $color-brand;
  }
}

.btn {
  @include btnAnchor;

  padding: 1rem;

  max-width: 15.2rem;
}

@media (max-width: $tablet-width) {
  .title {
    font-size: 3.3rem;
  }
}

@media (max-width: $mobile-width) {
  .membersImg {
    position: absolute;
    left: auto;
    right: 0;
    bottom: 0;
    transform: translateX(0);
    z-index: -1;

    min-width: 630px;
  }

  .title {
    margin-bottom: 1.5rem;

    font-size: 1.5rem;
  }

  .content {
    font-size: 1rem;
  }

  .text {
    margin-bottom: 1rem;
  }

  .btn {
    max-width: 17.5rem;
  }
}