@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.features {
  position: relative;
  margin-top: -196px;
  //padding: 3.5rem 0;
  //padding-top: 12.8rem;
  padding-top: 13.5%;
  z-index: 1;

  background-image: url("../../assets/images/cloud-desc.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
}

.bgWrap {
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;
  background-color: #ffffff;
}

.item {
  padding: 1.5rem 0;

  border-bottom: 2px solid #283954;

  &:last-child {
    border: none;
  }
}

.itemTitle {
  position: relative;

  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #002e5c;

  cursor: pointer;

  &::after {
    @include pseudo;

    top: 50%;
    right: 0;
    transform: translateY(-50%);

    width: 2.6875rem;
    height: 2.6875rem;

    background-image: url("../../assets/images/svg/icon-arrow.svg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
  }
}

.itemTitleOpened {
  &::after {
    transform: rotate(-90deg);

  }
}

.titleIcon {
  display: inline-block;
  width: 3rem;
  height: 3rem;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.itemPanel {
  padding-top: 2rem;

  max-width: 45.6rem;
}

@media (max-width: 1100px) {
  .itemTitle {
    font-size: 2.5rem;

    &::after {
      width: 2rem;
      height: 2rem;
    }
  }

  .titleIcon {
    width: 2.1rem;
    height: 2.1rem;
  }
}

@media (max-width: $tablet-width) {
  .itemTitle {
    font-size: 2.2rem;

    &::after {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .titleIcon {
    width: 1.8rem;
    height: 1.8rem;
  }
}

@media (max-width: $mobile-width) {
  .features {
    padding: 3rem 0;
    padding-top: 12.5%;
  }

  .bgWrap {
    padding-bottom: 0;
  }

  .item {
    padding: 1rem 0;
  }

  .itemTitle {
    font-size: 1.125rem;

    &::after {
      width: 1rem;
      height: 1rem;
    }
  }

  .titleIcon {
    width: 1rem;
    height: 1rem;
  }

  .itemPanel {
    padding-top: 1rem;
  }
}
