@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';


.title {
  position: relative;
  margin-bottom: 2.5rem;

  //display: flex;

  font-size: 4.5rem;
  font-weight: 900;
  color: #002e5c;
  text-transform: uppercase;
}

.shadowTitle {
  display: inline-block;

  color: #ffffff;
  text-shadow: 1px 0 0 #202651,
  0 1px 0 #202651,
  -1px 0 0 #202651,
  0 -1px 0 #202651;

  opacity: 0.2;
}

.shadowWrap {
  display: inline-block;
  width: 5000px;
  //display: flex;
  //white-space: nowrap;
}

.leftTitles {
  position: absolute;
  top: 0;
  right: 102%;
}

@media (max-width: $tablet-width) {
  .title {
    font-size: 3.3rem;
  }
}

@media (max-width: $mobile-width) {
  .title {
    margin-bottom: 1.5rem;

    font-size: 1.5rem;
  }

  .leftTitles {
    right: 101%;
  }
}
