@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.cityChoice {
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
  color: #000000;
  font-family: 'DIN Pro', 'Arial', sans-serif;
}

.container {
  width: 100%;
}

.date {
  font-family: 'DIN Pro', 'Arial', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 0.3125rem;
}
