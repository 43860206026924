@import '../../../../assets/styles/mixins.scss';

.accordion {
  margin-bottom: 1.5rem;
}

.accordionPanel {
  padding: 2rem 2.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  cursor: pointer;
}

.accordionContent {
  display: none;
  padding: 2rem;
  padding-bottom: 2.5rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.accordionOpened .accordionPanel {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordionOpened .panelTitle {
  &::before {
    transform: translateY(-40%) rotate(90deg);
  }
}

.accordionOpened .accordionContent {
  display: block;
}

.panelTitle {
  position: relative;
  font-family: 'DIN Pro Cond', sans-serif;
  font-size: 2rem;

  &::before {
    @include pseudo;

    top: 50%;
    right: 1.95rem;
    left: auto;
    width: 1rem;
    height: 1.813rem;
    background-image: url('../../../../assets/images/svg/left-arrow-slider.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: translateY(-50%) rotate(-90deg);
    transition: transform 0.3s linear;
  }
}

.countWrap {
  text-align: right;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
}

.count {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}

.info {
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(9.3rem, 1fr));
}

.infoLabel {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
}

.checkboxWrap {
  padding-left: 1.5rem;
}


.checkboxLabel {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  cursor: pointer;

  &::before {
    @include pseudo;

    padding: 0.25rem;
    top: -0.1rem;
    left: -1.5rem;
    width: 1rem;
    height: 1rem;
    border: 1px solid #000000;
    border-radius: 50%;
    background-clip: content-box;
  }
}

.checkbox:checked ~ .checkboxLabel::before {
  background-color: #000000;
}

.insText {
  font-size: 0.625rem;
  color: rgba(0, 0, 0, 0.5);
}

.countBtnsGroup {
  display: flex;
  align-items: center;
}

.countBtn {
  background-color: transparent;
  border: none;
  color: #000000;
  padding: 0;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.count {
  padding: 0 0.5rem;
  min-width: 2rem;
  text-align: center;
}

.price {
  text-align: right;
}

.totalPrice {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.nextPrice {
  font-size: 0.75rem;
}

.btnCart {
  @include btnPrimary;

  padding: 0.8rem;
  width: 100%;

  &:active:not(:disabled) {
    transform: scale(0.99, 0.95);
  }
  
  &:disabled {
    background-color: gray;
    cursor: default;
  }
}

.labelSquade {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include fontStyle(0.75rem, 1.2);
  font-weight: 400;
  color: rgba($color: #fff, $alpha: 0.5);
}

.textSquadeWrap {
  &:first-child {
    position: relative;
    margin-right: 3rem;

    &::after {
      @include pseudo;
      width: 2px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      right: -1.5rem; left: auto;
    }
  }
}

.squadeText {
  @include fontStyle(1rem, 1.2);
  font-weight: 700;
  color: #000000;
}

.selector {
  display: flex;
  align-items: center;
}


@media (max-width: $mobile-width) {
  .accordionPanel {
    padding: 1rem 1.5rem;
  }

  .panelTitle {
    font-size: 1.2rem;

    &::before {
      right: 0.6rem;
      width: 0.8rem;
      height: 1.613rem;
    }
  }

  .accordionContent {
    padding: 1rem;
    padding-bottom: 1.5rem;
  }

  .info {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6.3rem, 1fr));
  }
}
