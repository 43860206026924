@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.container {
  margin: 0 auto;
  padding: 0 10.3rem;

  max-width: $desktopWidth;

  overflow: hidden;
}

@media (max-width: $tablet-width) {
  .container {
    padding: 0 5rem;
  }
}

@media (max-width: $mobile-width) {
  .container {
    padding: 0 1.25rem;
  }
}