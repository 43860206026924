@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.levels {
  position: relative;
  padding-top: 7rem;
  padding-bottom: 4rem;
  z-index: 2;

  color: #ffffff;

  background-image: url("../../assets/images/level-bg.png");
  background-color: rgba(32, 38, 81, 0.7);
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  margin-bottom: 3.5rem;

  display: flex;
  flex-direction: column;

  font-size: 4.5rem;
  font-weight: 900;
  text-transform: uppercase;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  gap: 6.875rem;

  font-size: 1.125rem;
}

.icon {
  margin-bottom: 1rem;
}

.levelTitle {
  margin-bottom: 1rem;

  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;
}

@media (max-width: $mobile-width) {
  .levels {
    padding-top: 2rem;
    padding-bottom: 4.3rem;
  }

  .title {
    margin-bottom: 1.5rem;

    font-size: 1.5rem;
  }

  .list {
    gap: 1.5rem;

    font-size: 1rem;
  }

  .icon {
    margin-bottom: -0.9rem;
    margin-right: 0.375rem;
  }

  .levelTitle {
    display: inline-block;
  }
}
