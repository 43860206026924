// Цвета
$color-main: #ffffff;
$color-brand: #7298be;
$color-secondary: #28262e;
$color-focus: #fb4f64;

// Шрифт
$font-family-main: "Panton", sans-serif;

// Брейкпоинты
$desktopWidth: 1440px;
$tablet-width: 991px;
$mobile-width: 768px;

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
