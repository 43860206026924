@import 'mixins';

.desc {
  margin-top: 12.1875rem;
  margin-bottom: 5.125rem;
}

.desc__title {
  @include title42_53;
  display: inline-block;
  max-width: 34rem;
  padding-bottom: 0.75rem;
  margin-bottom: 3.75rem;
  border-bottom: 0.625rem solid #2D63B9;
}

.desc__video {
  position: relative;
  display: flex;
  width: 40.3125rem;
  height: 22.375rem;
  box-sizing: content-box;
  border: 0.25rem solid #2D63B9;
  margin-bottom: 2.5rem;
  filter: drop-shadow(0rem 0rem 1rem rgba(0, 0, 0, 0.65));

  & video {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}

.desc__video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 5.625rem;
  height: 5.625rem;
  padding: 0;
  background-color: white;
  border-radius: 50%;
  border: 0.1875rem solid #2D63B9;
  cursor: pointer;
  outline: none;
  transition: transform 0.25s ease;

  &::after {
    @include pseudo;

    width: 1.1875rem;
    height: 1.75rem;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    background-image: url('../images/svg/play.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &:active {
    transform: translate(-50%, -50%) scale(0.95);
  }
}

.desc__text-wrap {
  position: relative;
}

.desc__image {
  bottom: -2rem;
  right: -2rem;
  position: absolute;
}

.desc__text {
  font-size: 1.125rem;
  line-height: 1.55;
  letter-spacing: -0.02em;
  max-width: 35rem;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  &--bold {
    font-weight: 700;
  }
}

.desc__btn {
  margin-top: 2rem;
}

.desc__list {
  padding-top: 5.5rem;
}

.desc__item {
  position: relative;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10rem;
  }

  &:nth-child(odd) {
    justify-content: flex-end;

    & .desc__item-image {
      left: -4rem;
    }
  }

  &:nth-child(even) {
    & .desc__item-image {
      right: -6rem;
    }
  }
}
.desc__item-image {
  top: 50%;
  transform: translateY(-50%);

  &--sec {
    width: 45rem;
  }
}

.desc__textitem {
  width: 16.25rem;
  padding-top: 1.25rem;
  border-top: 0.625rem solid #2D63B9;

  font-size: 1.125rem;
  line-height: 1.55;
  letter-spacing: -0.02em;

  &:first-of-type {
    margin-right: 1.25rem;
  }
}

.desc__item-image {
  z-index: -1;
  position: absolute;
}

.desc__mob-image {
  display: none;
}

@media (max-width: 1440px) and (min-width: 1200px) {
  .desc__item {
    &:nth-child(even) {
      & .desc__item-image {
        right: -3rem;
      }
    }
  }
  .desc__image {
    bottom: 0;
    width: 35rem;
    right: -5rem;
  }

  .desc__item-image {
    width: 40rem;
  }
}

@media (max-width: 1199px) {
  .desc__item-image {
    transform: translateY(0);
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .desc {
    margin-top: 6.25rem;
    margin-bottom: 4rem;
  }

  .desc__image {
    position: static;
    margin: 0 auto;
    display: block;
    width: 38rem;
  }

  .desc__list {
    padding-top: 0;
  }

  .desc__item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .desc__item-image {
    position: static;
    order: 1;
    margin: 0 auto;
    width: 40rem;
  }

  .desc__textitem {
    width: 100%;
    margin-bottom: 2.5rem;

    &:first-of-type {
      margin-right: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .desc__text-wrap {
    margin-bottom: 2rem;
  }
}

@media (max-width: 991px) {
  .desc {
    margin-top: 4rem;
    margin-bottom: 0;
  }

  .desc__title {
    margin-bottom: 3rem;
  }

  .desc__list {
    padding-top: 0;
  }

  .desc__image {
    position: static;
  }

  .desc__item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .desc__item-image {
    position: static;
    order: 1;
  }

  .desc__textitem {
    width: 100%;
    margin-bottom: 1rem;

    &:first-of-type {
      margin-right: 0;
    }
  }

  .desc__picture {
    display: flex;
    justify-content: center;
  }

  .desc__video-btn {
    width: 4.625rem;
    height: 4.625rem;
  }
}

@media (max-width: 767px) {
  .desc {
    margin-top: 2.5rem;
  }

  .desc__title {
    @include title26_33;
    padding-bottom: 0.5rem;
    margin-bottom: 2.1875rem;
    border-width: 0.25rem;
    max-width: 17.5rem;
  }

  .desc__video {
    width: 100%;
    height: auto;
    margin-bottom: 1.875rem;
  }

  .desc__text {
    font-size: 0.8rem;
    line-height: 1.5;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  .desc__btn {
    margin-top: 1.875rem;
  }

  .desc__image {
    position: static;
    margin-bottom: 1.25rem;
  }

  .desc__list {
    padding-top: 0;
  }

  .desc__item {
    padding-top: 0rem;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 1.875rem;
    }
  }

  .desc__item-image {
    position: static;
    order: 1;
  }

  .desc__textitem {
    width: 100%;
    padding-top: 1.5rem;
    border-width: 0.3125rem;
    font-size: 1rem;
    margin-bottom: 0;

    &:first-of-type {
      margin-right: 0;
    }
  }

  .desc__mob-image {
    display: block;
  }
}