@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.description {
  margin-top: -150px;
  padding-top: 17.5%;

  color: #ffffff;

  background-image: url("../../assets/images/page-top-cloud.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
}

.hashtagWrap {
  margin-top: -2px;
  padding-bottom: 3.2rem;

  background-color: #ffffff;
}

.hashtag {
  font-size: 5.625rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 1.3;
  text-shadow: 1px 0 0 #202651,
  0 1px 0 #202651,
  -1px 0 0 #202651,
  0 -1px 0 #202651;
  white-space: nowrap;
}

.bgWrap {
  padding-top: 3.3rem;
  padding-bottom: 12.25rem;

  background-color: #002e5c;
}

.descWrapper {
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  gap: 1.875rem;
}

.title {
  margin-bottom: 1rem;

  font-size: 2.25rem;
  font-weight: 900;
  text-transform: uppercase;
}

.smile {
  display: inline-block;
  min-width: 2rem;

  color: transparent;

  background-image: url("../../assets/images/svg/icon-smile1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.content {
  font-size: 1.125rem;
}

.text {
  margin-bottom: 1rem;
}

.anchor {
  @include btnPrimary;

  margin-top: 1.5rem;
  padding: 1rem;

  max-width: 23.8125rem;

  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.videoWrapMobile {
  display: none;
}

@media (max-width: 1200px) {
  .bgWrap {
    padding-bottom: 14.25rem;
  }

  .descWrapper {
    display: block;
  }

  .videoWrapPc {
    display: none;
  }

  .videoWrapMobile {
    margin: 0 auto;

    display: block;
    max-width: 600px;
  }
}

@media (max-width: $mobile-width) {
  .hashtagWrap {
    padding-top: 2rem;
    padding-bottom: 2.6rem;
  }

  .hashtag {
    font-size: 2.25rem;
  }

  .bgWrap {
    padding-bottom: 12.5rem;
  }

  .descWrapper {
    display: block;
  }

  .title {
    font-size: 1.5rem;
  }

  .smile {
    min-width: 1.125rem;
  }

  .anchor {
    max-width: 17.5rem;
  }
}
