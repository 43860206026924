@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';


.options {
  padding-top: 3.5rem;
  padding-bottom: 4.6rem;
}

.title {
  margin-bottom: 4.75rem;

  font-size: 4.5rem;
  font-weight: 900;
  color: #002e5c;
  text-transform: uppercase;
}

.list {
  overflow: hidden;
}

.item {
  margin-bottom: 2.5rem;

  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;

  //&:first-child {
  //  transform: translateX(-60px);
  //}
  //
  //&:nth-child(2) {
  //  transform: translateX(30px);
  //}
  //
  //&:nth-child(3) {
  //  transform: translateX(15px);
  //}
}

.itemWrapPc {
  display: inline-block;
  white-space: nowrap;
}

.itemWrapMobile {
  display: none;
}

.option {
  margin-right: 2rem;

  min-width: max-content;
}

.optionImg {
  margin-right: 0.5rem;
}

@media (max-width: $tablet-width) {
  .title {
    font-size: 3.3rem;
  }
}

@media (max-width: $mobile-width) {
  .options {
    padding: 2rem 0;
  }

  .title {
    margin-bottom: 1.5rem;

    font-size: 1.5rem;
  }

  .list {
    padding: 0 1.5rem;
  }

  .item {
    margin-bottom: 0;

    font-size: 1.125rem;

    &:first-child {
      transform: translateX(0);
    }

    &:nth-child(2) {
      transform: translateX(0);
    }

    &:nth-child(3) {
      transform: translateX(0);
    }
  }

  .itemWrapPc {
    display: none;
  }

  .itemWrapMobile {
    display: block;
  }

  .option {
    margin-bottom: 0.5rem;
    margin-right: 0;

    display: block;
    min-width: auto;
  }

  .optionImg {
    width: 1.125rem;
  }
}
