@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 55.375rem;
  border-radius: 0.625rem;
  background-color: $color-main;

  &::after {
    @include pseudo;
    width: 90%;
    left: 5%;
    height: 90%;
    top: 5%;
    background-image: $color-main;
    filter: blur(35px);
    z-index: -1;
  }
}

.top {
  padding: 1.5rem 1.5rem 0;
  padding-right: 5rem;
  border-top-right-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
}

.topBorder {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #323232;
}

.titleWrap {
  padding-right: 1rem;
  margin-right: 1rem;
}

.title {
  font-size: 1.5rem;
  text-transform: uppercase;
  position: relative;

  &::after {
    @include pseudo;
    top: 0;
    width: 1px; height: 100%;
    background-color: black;
    left: auto; right: -1rem;
  }
}

.desc {
  font-size: 0.875rem;
  color: #000000;
  max-width: 15rem;
}

.logo {
  width: 100%;
  max-width: 5rem;
  margin-right: 1rem;
}

.data {
  font-size: 1.5rem;
  text-transform: uppercase;
}


.mainContent {
  padding: 1.5rem;
  padding-bottom: 2.5rem;
}

.mainContent_title {
  margin-bottom: 2rem;
  font-family: 'DIN Pro Cond', sans-serif;
  font-size: 1.75rem;
}

.popupCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.625rem;
  height: 3.125rem;
  background-color: #222222;
  border: none;
  border-top-right-radius: 0.625rem;
  cursor: pointer;

  &::after, &::before {
    @include pseudo;

    width: 1.5rem;
    height: 0.125rem;
    background-color: #5E5E5E;
    border-radius: 0.125rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.link {
  &:hover {
    color: rgba($color: #fff, $alpha: 1);
  }
}

@media (max-width: $tablet-width) {
  .topDate {
    font-size: 3rem;
  }

  .topDesc {
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }

  .logo {
    max-width: 9rem;
  }
}

@media (max-width: $mobile-width) {
  .wrapper {
    border-radius: 0;
  }

  .top {
    padding: 1.5rem 1rem;
    display: block;
  }

  .topBorder {
    flex-wrap: wrap;
  }

  .topDate {
    font-size: 2rem;
  }

  .btnWrap {
    margin-bottom: 1rem;
  }

  .btnPart {
    width: auto;
    margin-right: 0.4rem;
    margin-bottom: 1rem;
  }

  .mainContent {
    padding: 1rem;
    padding-bottom: 0.5rem;
  }

  .mainContent_title {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }

  .logo {
    max-width: 7rem;
    margin-bottom: 1rem;
  }

  .title {
    &::after {
      display: none;
    }
  }

  .desc {
    margin-bottom: 1rem;
  }

  .data {
    font-size: 1rem;
  }
}
