@import 'mixins';

.faq {
  margin-bottom: 7.9375rem;
}

.faq__title {
  @include title42-53;

  display: inline-block;
  padding-bottom: 0.5rem;
  border-bottom: 0.625rem solid #2D63B9;
  margin-bottom: 4.5625rem;
}

.faq__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.875rem;
}

.faq__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  padding: 2.125rem 1.75rem;
  background-color: white;
  border: 2px solid #283954;
}

.faq__sub, .faq__text {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.277;
}

.faq__sub {
  text-transform: uppercase;
  margin-bottom: 2.125rem;
}

.faq__text {
  font-weight: 400;
  margin-bottom: 2.5rem;
}

.faq__btn {
  margin-top: auto;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  color: #202651;
  background: none;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}

@media (max-width: 1440px) {
  .faq {
    margin-bottom: 6rem;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .faq {
    margin-bottom: 3.25rem;
  }

  .faq__title {
    font-size: 2rem;
    margin-bottom: 3.25rem;
  }

  .faq__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .faq {
    margin-bottom: 2.5rem;
  }

  .faq__title {
    margin-bottom: 3.125rem;
    padding-bottom: 0.875rem;
    font-size: 1.625rem;
    border-width: 0.25rem;
  }

  .faq__list {
    display: block;
  }

  .faq__item {
    padding: 1.875rem 1.25rem;

    &:not(:last-of-type) {
      margin-bottom: 1.875rem;
    }
  }
}