@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';


.title {
  @include mainTitle;

  margin-bottom: 3rem;
}

.list {
  margin-bottom: 40px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  justify-content: center;
  gap: 25px
}

.partner {
  position: relative;
  padding: 37px 48px;

  display: grid;
  justify-self: center;
}

.logo {
  width: 100%;
  max-width: 14rem;
  height: 4.6rem;
  object-fit: contain;
  justify-self: center;
}

.partnerInfo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: grid;
  align-content: center;
  place-items: center;

  font-size: 22px;
  color: #ffffff;

  background-color: rgba(32, 32, 32, 0.6);
}

.infoToggle {
  position: absolute;
  top: 20px;
  right: 20px;

  width: 22px;
  height: 23px;

  background-color: transparent;
  background-image: url("../../assets/images/svg/icon-info.svg");
  background-repeat: no-repeat;
  border: none;
  opacity: 0.3;

  cursor: pointer;

  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }
}

@media (max-width: $tablet-width) {
  .title {
    font-size: 3.3rem;
  }
}

@media (max-width: $mobile-width) {
  .title {
    margin-bottom: 3.5rem;

    font-size: 1.5rem;
  }

  .partners {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}