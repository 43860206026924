/* *************************************** */
/* DIN Pro FONT */

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Black'),
    url('../fonts/din_pro_black.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/din_pro_black.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../fonts/din_pro_black.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 900;
    font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Bold'),
    url('../fonts/din_pro_bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/din_pro_bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../fonts/din_pro_bold.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: bold;
    font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro'),
    url('../fonts/din_pro.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/din_pro.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../fonts/din_pro.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 400;
    font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Medium'),
    url('../fonts/din_pro_medium.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/din_pro_medium.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../fonts/din_pro_medium.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 500;
    font-style: normal;
}